/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxPlural' })
export default class PluralPipe implements PipeTransform {
  transform(input: number, label: string, pluralLabel = ''): string {
    input = input || 0;
    return input === 1 ? `${input} ${label}` : pluralLabel ? `${input} ${pluralLabel}` : `${input} ${label}s`;
  }
}
