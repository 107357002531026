<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <div>{{'change_password.title' | translate}}</div>
    <nb-icon icon="close-outline" (click)="dialogRef.close()"></nb-icon>
  </nb-card-header>

  <nb-card-body>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <!-- Old Password Field with Toggle -->
      <label for="oldPassword" class="label">
        {{'change_password.old_password' | translate}} <span class="text-danger">*</span>
      </label>
      <div class="password-input-container">
        <input
          [type]="showOldPassword ? 'text' : 'password'"
          nbInput
          fullWidth
          formControlName="oldPassword"
          placeholder="Old Password"
          [status]="getFormControlStatus('oldPassword')"
          class="mt-2 mb-1"
        />
        <button nbSuffix nbButton ghost (click)="togglePasswordVisibility('oldPassword')" type="button">
          <nb-icon [icon]="showOldPassword ? 'eye-outline' : 'eye-off-2-outline'"></nb-icon>
        </button>
      </div>
      <div *ngIf="isInvalid('oldPassword')" class="mt-0">
        <div *ngIf="hasError('oldPassword', 'required')" class="error-message">
          {{'change_password.errors.old_password_required' | translate}}
        </div>
        <div *ngIf="hasError('oldPassword', 'maxlength')" class="error-message">
          {{'change_password.errors.max_length' | translate}}
        </div>
      </div>

      <!-- New Password Field with Toggle -->
      <label for="newPassword" class="label">
        {{'change_password.new_password' | translate}} <span class="text-danger">*</span>
      </label>
      <div class="password-input-container">
        <input
          [type]="showNewPassword ? 'text' : 'password'"
          nbInput
          fullWidth
          formControlName="newPassword"
          placeholder="New Password"
          [status]="getFormControlStatus('newPassword')"
          class="mt-2 mb-1"
        />
        <button nbSuffix nbButton ghost (click)="togglePasswordVisibility('newPassword')" type="button">
          <nb-icon [icon]="showNewPassword ? 'eye-outline' : 'eye-off-2-outline'"></nb-icon>
        </button>
      </div>
      <div *ngIf="isInvalid('newPassword')" class="mt-0">
        <!-- Add the error messages for 'newPassword' -->
        <div *ngIf="hasError('newPassword', 'required')" class="error-message">
          {{'change_password.errors.new_password_required' | translate}}
        </div>
        <div *ngIf="hasError('newPassword', 'minlength')" class="error-message">
          {{'change_password.errors.min_length' | translate}}
        </div>
        <!-- Additional validations... -->
      </div>

      <!-- Confirm Password Field with Toggle -->
      <label for="confirmPassword" class="label">
        {{'change_password.confirm_password' | translate}} <span class="text-danger">*</span>
      </label>
      <div class="password-input-container">
        <input
          [type]="showConfirmPassword ? 'text' : 'password'"
          nbInput
          fullWidth
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          [status]="getFormControlStatus('confirmPassword')"
          class="mt-2 mb-1"
        />
        <button nbSuffix nbButton ghost (click)="togglePasswordVisibility('confirmPassword')" type="button">
          <nb-icon [icon]="showConfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"></nb-icon>
        </button>
      </div>
      <div *ngIf="isInvalid('confirmPassword')" class="mt-0">
        <!-- Add the error messages for 'confirmPassword' -->
        <div *ngIf="hasError('confirmPassword', 'required')" class="error-message">
          {{'change_password.errors.confirm_password_required' | translate}}
        </div>
        <div *ngIf="hasError('confirmPassword', 'passwordMismatch')" class="error-message">
          {{'change_password.errors.mismatch' | translate}}
        </div>
      </div>
    </form>

    <!-- Submit and Reset Buttons -->
    <div class="mt-2">
      <button
        nbButton
        hero
        class="float-right ml-2"
        status="primary"
        (click)="onSubmit()"
        [disabled]="myForm.invalid"
      >
        {{'global.submit' | translate}}
      </button>
      <button
        nbButton
        hero
        class="float-right ml-2"
        status="danger"
        (click)="resetForm()"
      >
        {{'global.reset' | translate}}
      </button>
    </div>
  </nb-card-body>
</nb-card>
