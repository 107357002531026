import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/service/auth.service';
import {
  ImportQuestionAPI,
  PrintDetails,
  PrintQuestionPaperAPI,
} from '../../../modules/interface/questions.interface';
import { GetExportedAPI } from '../../../modules/interface/getExams.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionBankService {
  BASE_URL = '';

  userId = '';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  static getQuerySingleParam(name, value) {
    let str = '&';
    str += `${name}=${value}`;
    return str;
  }

  deleteQuestion(questionId: string, trainingProviderId: string) {
    const API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/question/${questionId}`;
    return this.httpClient.delete<unknown>(API_URL).pipe(
      map((data: unknown) => data),
      catchError((error) => throwError(error)),
    );
  }

  getQuestions<T>(
    trainingProviderId: string,
    sectionId: string,
    subsectionId: string,
    apiPage?: number,
    maxRows?: number,
    searchTerm?: string,
    sortingColumn?: string,
    complexity?: string,
  ): Observable<T> {
    let API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/question/getQuestions?sectionId=${sectionId}&subSectionId=${subsectionId}&currentPage=${apiPage}&maxRows=${maxRows}&searchTerm=${searchTerm}&sortingColumn=${sortingColumn}`;

    if (complexity !== undefined && complexity !== '') {
      API_URL += QuestionBankService.getQuerySingleParam(
        'complexity',
        complexity,
      );
    }

    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  exportQuestions(
    trainingProviderId: string,
    sectionId: string,
    subsectionId?: string,
    complexity?: string,
    sortingColumn?: string,
  ): Observable<GetExportedAPI> {
    let API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/questions/export?sectionId=${sectionId}`;

    if (subsectionId !== undefined && subsectionId !== '') {
      API_URL += `&subSectionId=${subsectionId}`;
    }

    if (complexity !== undefined && complexity !== '') {
      API_URL += QuestionBankService.getQuerySingleParam(
        'complexity',
        complexity,
      );
    }

    if (sortingColumn !== undefined && sortingColumn !== '') {
      API_URL += QuestionBankService.getQuerySingleParam(
        'SortingColumn',
        sortingColumn,
      );
    }

    return this.httpClient.get(API_URL).pipe(
      map((data: GetExportedAPI) => data),
      catchError((error) => throwError(error)),
    );
  }

  getQuestionById<T>(
    trainingProviderId: string,
    questionId: string,
  ): Observable<T> {
    const API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/question/${questionId}`;
    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  getQuestionComplexities<T>(): Observable<T> {
    const API_URL = `${environment.apiUrl}/api/question/complexities`;
    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  printQuestionPaper(questionPaper): Observable<PrintQuestionPaperAPI> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpaper/printquestionpaper`;
    // const API_URL = `http://localhost:3004/api/company/${userObject.user.trainingProviderId}/questionpaper/printquestionpaper`;
    return this.httpClient
      .post<PrintQuestionPaperAPI>(API_URL, questionPaper)
      .pipe(
        map((data: PrintQuestionPaperAPI) => data),
        catchError((error) => throwError(error)),
      );
  }

  updateQuestionPaper(
    questionPaper,
    printdetailsId: string,
  ): Observable<PrintQuestionPaperAPI> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpaper/printquestionpaper/${printdetailsId}`;
    return this.httpClient
      .put<PrintQuestionPaperAPI>(API_URL, questionPaper)
      .pipe(
        map((data: PrintQuestionPaperAPI) => data),
        catchError((error) => throwError(error)),
      );
  }

  getQuesFromPrintDetails(
    serviceId: string,
    isPublished?: number,
  ): Observable<PrintDetails> {
    const userObject = this.authService.getUser();
    // const API_URL = `${environment.apiUrl}/api/company/${this.dataa.user.trainingProviderId}/service/${serviceId}/questions`;
    let API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpapers/list?serviceId=${serviceId}`;
    if (isPublished) API_URL += `&isPublished=${isPublished}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${userObject.token}`, // Add authorization header if required
      'Content-Type': 'application/json', // Add other headers as needed
    });

    const options = { headers };
    return this.httpClient.get<PrintDetails>(API_URL, options).pipe(
      map((data: PrintDetails) => data),
      catchError((error) => throwError(error)),
    );
  }

  // getQuesFromPrintDetailsbyService(serviceId: string): Observable<PrintDetails> {
  //   const userObject = this.authService.getUser();
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${userObject.token}`, // Add authorization header if required
  //     'Content-Type': 'application/json', // Add other headers as needed
  //   });

  //   // const API_URL = `${environment.apiUrl}/api/company/${this.dataa.user.trainingProviderId}/service/${serviceId}/questions`;
  //   let API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpapers/list?serviceId=${serviceId}`;
  //   if (isPublished) API_URL += `&isPublished=${isPublished}`;
  //   return this.httpClient.get<PrintDetails>(API_URL).pipe(
  //     map((data: PrintDetails) => data),
  //     catchError((error) => throwError(error)),
  //   );
  // }

  getQuesFromPrintDetailsbyService(
    serviceId: string,
    currentPage: number,
    maxRows: number,
    searchTerm = '',
    sortingColumn = '',
  ): Observable<PrintDetails> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/service/${serviceId}/questions`;

    const params = new HttpParams()
      .set('currentPage', currentPage.toString())
      .set('maxRows', maxRows.toString())
      .set('searchTerm', searchTerm)
      .set('sortingColumn', sortingColumn);

    return this.httpClient.get<PrintDetails>(API_URL, { params }).pipe(
      map((data: PrintDetails) => data),
      catchError((error) => throwError(error)),
    );
  }

  createQuestion(question, trainingProviderId: string) {
    const userObject = this.authService.getUser();
    const headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${userObject.token}`);
    headers.append('Accept', 'application/json');
    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/question`;
    return this.httpClient.post(API_URL, question, options).pipe(
      map((data: { status: string }) => data),
      catchError((error) => throwError(error)),
    );
  }

  importQuestions(questions: FormData): Observable<ImportQuestionAPI> {
    const userObject = this.authService.getUser();
    const headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${userObject.token}`);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/question/import`;
    return this.httpClient
      .post<ImportQuestionAPI>(API_URL, questions, options)
      .pipe(
        map((data: ImportQuestionAPI) => data),
        catchError((error) => throwError(error)),
      );
  }

  updateQuestion(
    form: FormData,
    trainingProviderId: string,
    questionId: string,
  ) {
    const userObject = this.authService.getUser();
    const headers = new HttpHeaders();
    headers.append('Authorization', `Bearer ${userObject.token}`);
    // headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/question/${questionId}`;
    return this.httpClient.put<unknown>(API_URL, form, options).pipe(
      map((data: { status: string }) => data),
      catchError((error) => throwError(error)),
    );
  }
}
