/* eslint-disable no-param-reassign */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @ViewChild('video') videoElement: ElementRef;

  @ViewChild('canvas') canvas: ElementRef;

  trainingProviderId: string;

  showWebcam = false;

  user: {
    emailAddress: string
    hallTicket: string
    firstName: string
    lastName: string
    mobileNumber: string
    trainingProviderId: string
    profilePicture?: string
  } = {
      emailAddress: '',
      hallTicket: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
      trainingProviderId: '',
    };

  selectedImage: string | null = null;

  fileSizeError = false;


  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: NbToastrService,
    private translate: TranslateService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParamMap.subscribe((routeParams) => {
      this.trainingProviderId = routeParams.get('trainingProviderId');
      this.user.emailAddress = routeParams.get('email');
    });
    if (!this.trainingProviderId) { this.router.navigate(['auth/login']);}
  }

  submitted = false;

  ngOnInit(): void {
    this.user.trainingProviderId = this.trainingProviderId;
    if (localStorage.getItem('userData')) {
      localStorage.removeItem('userData');
      localStorage.removeItem('examDetails');
    }
  }


  initializeWebcam() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.showWebcam = true;
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoElement.nativeElement.srcObject = stream;
        })
        .catch(err => {
          this.toastr.danger(
            this.translate.instant('employee.exam.image_capture_registration'),
            this.translate.instant('employee.exam.image_capture_title'),
          );
          console.error('Error accessing webcam', err);
          this.showWebcam = false;
        });
    }
  }

  capture() {
    const context = this.canvas.nativeElement.getContext('2d');
    context.drawImage(this.videoElement.nativeElement, 0, 0, 320, 240);
    this.selectedImage = this.canvas.nativeElement.toDataURL('image/png');
    // console.log('this is the selected image', this.selectedImage)
    this.user.profilePicture = this.selectedImage;
    // Close the stream
    if (this.videoElement.nativeElement.srcObject) {
      this.videoElement.nativeElement.srcObject.getTracks().forEach(track => track.stop());
    }
  }

  trimValues(value) {
    if (value === 'emailAddress') this.user.emailAddress = this.user.emailAddress.trim();
    if (value === 'hallTicket') this.user.hallTicket = this.user.hallTicket.trim();
    if (value === 'firstName') this.user.firstName = this.user.firstName.trim();
    if (value === 'lastName') this.user.lastName = this.user.lastName.trim();
  }

  async register() {
    this.authService.register(this.trainingProviderId, this.user).subscribe(
      (res: {status: string}) => {
        if (res.status === 'Success') {
          this.toastr.success(this.translate.instant('candidate_register.register_success'),
            this.translate.instant('candidate_register.register_success_message'));
          const loginData = localStorage.getItem('userData');
          if (loginData) {
            // this.router.navigate(['pages/training-provider/services']);
            this.authService.logOut();
          } else this.router.navigate(['auth/login']);
        }
      },
      (err) => {
        if (err.status === 400) {
          if (err.error && err.error.errors && err.error.errors.length > 0) {
            const { errorCodes } = err.error.errors[0];
            if (errorCodes && errorCodes.length > 0) {
              const { errorCode } = errorCodes[0];

              if (errorCode !== 'USER_ALREADY_EXITS') {
                this.toastr.danger(this.translate.instant('candidate_register.register_failed_400'),
                  this.translate.instant('candidate_register.error_400'));
              }
            }
          }

          if (err.error && err.error.errors && err.error.errors.length > 0) {
            const { errorCodes } = err.error.errors[0];
            if (errorCodes && errorCodes.length > 0) {
              const { errorCode } = errorCodes[0];

              if (errorCode === 'USER_ALREADY_EXITS') {
                if (err.error.errors[0]?.key === 'email') this.toastr.danger(
                  this.translate.instant('candidate_register.email_exists'),
                  this.translate.instant('candidate_register.registration_failed'));
                else this.toastr.danger(this.translate.instant('candidate_register.user_exists'),
                  this.translate.instant('candidate_register.registration_failed'));
                return;
              }
            }
          }
        }

        if (err.status === 500) {
          if (err.error && err.error.errors && err.error.errors.length > 0) {
            const { errorCodes } = err.error.errors[0];
            if (errorCodes && errorCodes.length > 0) {
              const { errorCode } = errorCodes[0];

              if (errorCode === 'INTERNAL_SERVER_ERROR') {
                this.toastr.danger(this.translate.instant('candidate_register.internal_server_error'),
                  this.translate.instant('candidate_register.registration_failed'));
                return;
              }
            }
          }
        }

        if (err.status === 404) {
          if (err.error && err.error.errors && err.error.errors.length > 0) {
            const { errorCodes } = err.error.errors[0];
            if (errorCodes && errorCodes.length > 0) {
              const { errorCode } = errorCodes[0];

              if (errorCode === 'INVALID_EMAIL') {
                this.toastr.danger(this.translate.instant('candidate_register.user_not_found'),
                  this.translate.instant('candidate_register.registration_failed'));
                return;
              }
            }
          }
        }

        // Show the generic toastr for other error cases
        this.toastr.danger(this.translate.instant('candidate_register.error_message'),
          this.translate.instant('candidate_register.registration_failed'));
      },

    );
  }

  base64toBlob(base64Data, contentType) {
    // eslint-disable-next-line no-param-reassign
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  convertJsonToFormData(jsonData): FormData {
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const key in jsonData) {
      if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
        const value = jsonData[key];
        // Handle undefined values

        if ((typeof value === 'string' &&
          (value.toLowerCase() === 'undefined' || value.toLowerCase() === 'null'))
          || value === undefined || value === null) {
          formData.append(key, '');
        } else if (value instanceof File || value instanceof Blob) {
          // Handle files separately
          formData.append(key, value, `${this.user.firstName + this.user.lastName}.png`);
        } else {
          formData.append(key, value);
        }
      }
    }
    // Ref from IR
    // formData.append('Request', JSON.stringify(jsonData));

    return formData;
  }

  deselectImage(): void {
    this.selectedImage = null;
    const fileInput = document.getElementById('profilePicture') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = null;
    }
  }
}
