/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableFilterService {

  filterData;

  constructor() {
    const storedData = localStorage.getItem('filterData');
    if (storedData) {
      this.filterData = JSON.parse(storedData);
    }
  }

  async setFilterValue(page: string, filter) {
    if (!this.filterData) this.filterData = {};
    this.filterData[page] = await filter;

    await localStorage.setItem('filterData', await JSON.stringify(this.filterData));
  }

  getFilterValue(page: string) {
    if (this.filterData && this.filterData[page]) {
      return this.filterData[page];
    }
    return null;
  }

  checkFilterValue(page: string, initValue, actualValue) {
    if (this.getFilterValue(page) === null && !actualValue) {
      return initValue;
    }
    return this.getFilterValue(page);
  }

  deleteFilterValue(parentKey, propertyKey) {
    if (this.filterData[parentKey] && this.filterData[parentKey][propertyKey]) {
      delete this.filterData[parentKey][propertyKey];

      localStorage.setItem('filterData', JSON.stringify(this.filterData));
    }
  }

}
