import { Component } from '@angular/core';

@Component({
  selector: 'ngx-batch-allocation',
  templateUrl: './batch-allocation.component.html',
  styleUrls: ['./batch-allocation.component.scss'],
})
export class BatchAllocationComponent {

}
