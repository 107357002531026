<h1 id="title" class="title" *ngIf="trainingProviderId">
  {{ 'candidate_register.register_title' | translate }}
</h1>
<h1 id="title" class="title" *ngIf="!trainingProviderId">
  {{ 'candidate_register.profile_title' | translate }}
</h1>
<form
  (ngSubmit)="submit()"
  #registerForm="ngForm"
  aria-labelledby="title">
  <div class="form-group">
    <label for="hallTicket" class="label">
      {{ 'candidate_register.hall_ticket' | translate }}
      <span class="text-danger">*</span>
    </label>
    <input
      type="text"
      nbInput
      fullWidth
      [(ngModel)]="user.hallTicket"
      id="hallTicket"
      name="hallTicket"
      placeholder="Enter hall ticket"
      #ticket="ngModel"
      pattern="^\S*$"
      (blur)="trimValues('hallTicket')"
      required
    />
    <div *ngIf="ticket.invalid && (ticket.dirty || ticket.touched)">
      <div *ngIf="ticket.errors.required" class="error-message">
        {{ 'candidate_register.hall_ticket_required' | translate }}
      </div>
    </div>
    <div *ngIf="ticket.errors?.pattern"  class="error-message">
      {{ 'candidate_register.Enter_valid_hall_ticket' | translate }}
    </div>
  </div>
  <div class="row">
    <div class="form-group col-6">
      <label for="firstName" class="label">
        {{ 'candidate_register.first_name' | translate }}
        <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        nbInput
        fullWidth
        [(ngModel)]="user.firstName"
        id="firstName"
        name="firstName"
        placeholder="First name"
        #firstName="ngModel"
        (blur)="trimValues('firstName')"
        required
      />
      <div *ngIf="firstName.errors?.pattern"  class="error-message">
        {{ 'candidate_register.Enter_valid_name' | translate }}
      </div>
      <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
        <div *ngIf="firstName.errors.required" class="error-message">
          {{ 'candidate_register.first_name_required' | translate }}
        </div>
      </div>
    </div>
    <div class="form-group col-6">
      <label for="lastName" class="label">
        {{ 'candidate_register.last_name' | translate }}
        <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        nbInput
        fullWidth
        [(ngModel)]="user.lastName"
        id="lastName"
        name="lastName"
        placeholder="Last name"
        #lastName="ngModel"
        (blur)="trimValues('lastName')"
        required
      />
      <div *ngIf="lastName.errors?.pattern"  class="error-message">
        {{ 'candidate_register.Enter_valid_name' | translate }}
      </div>
      <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
        <div *ngIf="lastName.errors.required" class="error-message">
          {{ 'candidate_register.last_name_required' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="emailAddress" class="label">
      {{ 'candidate_register.email_address' | translate }}
      <span class="text-danger">*</span>
    </label>
    <input
      type="email"
      nbInput
      fullWidth
      [(ngModel)]="user.emailAddress"
      id="emailAddress"
      name="email"
      placeholder="Email"
      pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{1,5}$"
      #email="ngModel"
      (blur)="trimValues('emailAddress')"
      [disabled]="!canEditEmail"
      required
      email
    />
    <div *ngIf="email.invalid && (email.dirty || email.touched)">
      <div *ngIf="email.errors.required" class="error-message">
        {{ 'candidate_register.email_required' | translate }}
      </div>
      <div *ngIf="email.errors.email ||
        email.errors.pattern" class="error-message">
        {{ 'candidate_register.invalid_email' | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="mobileNumber" class="label">
      {{ 'candidate_register.mobile_number' | translate }}
      <span class="text-danger">*</span>
    </label>
    <input
      type="tel"
      nbInput
      fullWidth
      [(ngModel)]="user.mobileNumber"
      id="mobileNumber"
      name="mobileNumber"
      placeholder="Enter mobile number"
      #mobile="ngModel"
      pattern="^(\+\d{1,3}[- ]?)?\d{10}$"
      required
    />
    <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)">
      <div *ngIf="mobile.errors.required" class="error-message">
        {{ 'candidate_register.mobile_number_required' | translate }}
      </div>
      <div *ngIf="mobile.errors.pattern" class="error-message">
         Please enter valid mobile number
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="profilePicture" class="label">
      Add profile picture
      <span class="text-danger">*</span>
    </label>
    <div class="file-input-container">
      <button *ngIf="!showWebcam" type="button"
       class="btn btn-primary select-btn" 
              (click)="initializeWebcam()">
          Take Picture
      </button>
      <div class="webcam-container" *ngIf="showWebcam">
          <video #video width="320" height="240"
           autoplay class="mirror-video"></video>
          <canvas #canvas width="320" height="240"
           style="display:none;"></canvas>
          <button type="button" class="btn btn-primary capture-btn"
           (click)="capture()">
              <i class="fa fa-camera"></i>
          </button>
      </div>
      
      <img *ngIf="selectedImage && !showWebcam" [src]="selectedImage" class="selected-image" />
      <button *ngIf="selectedImage && !showWebcam"
              type="button" class="deselect-btn" (click)="deselectImage()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </button>
  </div>
  
  </div>
  <button type="submit"
   [disabled]="registerForm.invalid || !selectedImage"
    nbButton fullWidth status="success" *ngIf="trainingProviderId">
    {{ 'candidate_register.submit' | translate }}
  </button>
  <button type="submit"
   [disabled]="registerForm.invalid || !selectedImage"
    nbButton fullWidth status="success" *ngIf="!trainingProviderId">
    {{ 'candidate_update_profile.update' | translate }}
  </button>
</form>
