import { AbstractControl, ValidatorFn, ValidationErrors, Validators } from '@angular/forms';

export function blankSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    // Check if the string contains only blank spaces
    if (value && /^\s+$/.test(value)) {
      // Return an error if only blank spaces are found
      return { 'blankSpace': true };
    }

    // Return null if the validation passes
    return null;
  };
}
export function dynamicMinLengthValidator(minLengthField: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const minLength = control.get(minLengthField)?.value || 0; // Get the value from the minLengthField
    // Use the built-in Validators.minLength and pass the dynamic minLength
    return Validators.min(minLength)(control);
  };
}

export function passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const passwordControl = group.get(controlName);
    const confirmPasswordControl = group.get(matchingControlName);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    }

    return null;
  };
}

export const capitalCaseValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;
  const capitalCaseRegex = /[A-Z]/;

  return capitalCaseRegex.test(value) ? null : { noCapitalCase: true };
};

export const smallCaseValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;
  const smallCaseRegex = /[a-z]/;

  return smallCaseRegex.test(value) ? null : { noSmallCase: true };
};

export const numberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;
  const numberRegex = /\d/;

  return numberRegex.test(value) ? null : { noNumber: true };
};

export const specialCharacterValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const { value } = control;
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

  return specialCharacterRegex.test(value) ? null : { noSpecialCharacter: true };
};
