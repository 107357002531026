import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { LoginApiResponse, UserData } from '../../modules/interface/auth.interface';
import { MenuService } from '../../services/menu/menu.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn = false;

  userObject: UserData;

  userRole = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private menuService: MenuService,
    private toastr: NbToastrService,
    private translate: TranslateService,
  ) {}

  getUser() {
    this.userObject = JSON.parse(localStorage.getItem('userData') as string);

    if (this.userObject) {
      return this.userObject;
    }
    return null;
  }

  hasAccess(): boolean {
    const userLoaclState = localStorage.getItem('userData');

    if (userLoaclState) {
      this.userObject = JSON.parse(userLoaclState);
    } else {
      return false;
    }
    if (this.userObject.userRole) {
      this.loggedIn = true;
      this.menuService.setSideMenuOptions(this.userObject.userRole);
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  login(email: string, passwd: string) {
    const loginData = {
      emailAddress: email,
      password: passwd,
    };
    this.http.post(`${environment.apiUrl}/api/login`, loginData).subscribe(
      (res: LoginApiResponse) => {
        if (res.status === 'Success') {
          this.loggedIn = true;
          // this.router.navigate(['']);
          // res.data.userRole = 'Super Admin';
          this.userRole = res.data.userRole;
          localStorage.setItem('userData', JSON.stringify(res.data));
          switch (res.data.userRole) {
            case 'TrainingProvider':
              this.userRole = 'TrainingProvider';
              this.menuService.activeMenu = this.menuService.companyMenu;
              this.router.navigate(['pages/training-provider/services']);
              break;
            case 'SuperUser':
              this.userRole = 'SuperUser';
              this.menuService.activeMenu = this.menuService.superAdminMenu;
              this.router.navigate(['pages/super-admin/companies']);
              break;
            case 'Trainee':
              this.userRole = 'Candidate';
              this.menuService.activeMenu = this.menuService.candidateMenu;
              this.router.navigate(['pages/candidate/exams-list']);
              break;
            // Add cases for other user types
            default:
              this.router.navigate(['']);
              break;
          }
          this.getUser();
        }
      },
      (err) => {
        if (err.status === 400) {
          this.loggedIn = false;
          this.toastr.danger(this.translate.instant('candidate_login.error_400'),
            this.translate.instant('candidate_login.login_error'));
        } else {
          this.loggedIn = false;
          this.toastr.danger(this.translate.instant('candidate_login.error_message'),
            this.translate.instant('candidate_login.login_error'));
        }
      },
    );
    if (this.loggedIn) {
      return true;
    }
    return false;
  }

  register(trainingProviderId: string, registerData) {
    const API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/user/registration`;

    return this.http.post<unknown>(API_URL, registerData).pipe(
      map((data: { status: string }) => data),
      catchError((error) => throwError(error)),
    );
  }

  logOut() {
    localStorage.removeItem('userData');
    localStorage.removeItem('examDetails');
    this.loggedIn = false;
    this.router.navigate(['/auth/login']);
  }
}
