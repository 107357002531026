<nb-card>
  <nb-card-header>
    <div class="header-content">
      {{ 'global.delete_message' | translate }}
      <nb-icon icon="close-outline" class="closeIcon float-right"
      (click)="this.dialogRef.close()"></nb-icon>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="circle-icon">
      <nb-icon icon="alert-triangle-outline" class="warn"></nb-icon>
    </div>
    <div *ngIf="message.length > maxLength">
      <strong>{{title}} : {{ message | slice: 0 : SliceLimit }}
        ...
        {{
          message
            | slice
              : message.length - SliceLimit
              : message.length
        }}</strong>
    </div>
    <div *ngIf="message.length <= maxLength">
      <strong>{{title}} : {{message}}</strong>
    </div>
  </nb-card-body>
  <nb-card-footer class="end">
    <button nbButton hero status="danger" class="float-right ml-2"
    (click)="confirm()">{{ 'global.delete' | translate }}</button>

    <button nbButton hero status="primary" class="float-right ml-2"
    (click)="cancel()">{{ 'global.cancel' | translate }}</button>
  </nb-card-footer>
</nb-card>
