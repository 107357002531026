import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { LoginApiResponse, UserData } from '../../modules/interface/auth.interface';
import { MenuService } from '../../services/menu/menu.service';

interface UserProfile {
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  userType: number | null;
  mobileNumber: string | null;
  salutation: null;
  emergencyContactNumber: string | null;
  emailAddress: string | null;
  registrationNumber: string | null;
  password: string | null;
  hallTicket: string | null;
  questionPaperSetNumber: number | null;
  linkedinId: string | null;
  facebookId: string | null;
  isAcceptedTermsAndConditions: boolean | null;
  unSubscribe: number | null;
  profilePicPath: string | null;
  traineePhoto: string | null;
  fingerPrintValue: string | null;
  thumbImpression: string | null;
  gender: string | null;
  dateOfBirth: string | null;
  comments: string | null;
  candidateComments: string | null;
  trainingProviderId: string | null;
  status: number | null;
  createdBy: string | null;
  createdOn: string | null;
  modifiedBy: string | null;
  modifiedOn: string | null;
  address1Street1: string | null;
  address1Street2: string | null;
  address1City: string | null;
  address1State: string | null;
  address1Country: string | null;
  address1Pincode: string | null;
  address2Street1: string | null;
  address2Street2: string | null;
  address2City: string | null;
  address2State: string | null;
  address2Country: string | null;
  address2Pincode: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn = false;

  userObject: UserData;

  userRole = '';

  profilePicPath = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private menuService: MenuService,
    private toastr: NbToastrService,
    private translate: TranslateService,
  ) {}

  userProfileData = this.getUser();

  getUser() {
    this.userObject = JSON.parse(localStorage.getItem('userData') as string);
    if (this.userObject) {
      return this.userObject;
    }
    return null;
  }

  hasAccess(): boolean {
    const userLoaclState = localStorage.getItem('userData');

    if (userLoaclState) {
      this.userObject = JSON.parse(userLoaclState);
    } else {
      return false;
    }
    if (this.userObject.userRole) {
      this.loggedIn = true;
      this.menuService.setSideMenuOptions(this.userObject.userRole);
    } else {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  login(email: string, passwd: string) {
    const loginData = {
      emailAddress: email,
      password: passwd,
    };
    this.http.post(`${environment.apiUrl}/api/login`, loginData).subscribe(
      (res: LoginApiResponse) => {
        if (res.status === 'Success') {
          this.loggedIn = true;
          // this.router.navigate(['']);
          // res.data.userRole = 'Super Admin';
          this.userRole = res.data.userRole;
          localStorage.setItem('userData', JSON.stringify(res.data));
          switch (res.data.userRole) {
            case 'TrainingProvider':
              this.userRole = 'TrainingProvider';
              this.menuService.activeMenu = this.menuService.companyMenu;
              this.router.navigate(['pages/training-provider/services']);
              break;
            case 'SuperUser':
              this.userRole = 'SuperUser';
              this.menuService.activeMenu = this.menuService.superAdminMenu;
              this.router.navigate(['pages/super-admin/companies']);
              break;
            case 'Trainee':
              this.userRole = 'Candidate';
              this.menuService.activeMenu = this.menuService.candidateMenu;
              this.router.navigate(['pages/candidate/exams-list']);
              break;
            // Add cases for other user types
            default:
              this.router.navigate(['']);
              break;
          }
          this.getUser();
        }
      },
      (err) => {
        if (err.status === 400) {
          this.loggedIn = false;
          this.toastr.danger(this.translate.instant('candidate_login.error_400'),
            this.translate.instant('candidate_login.login_error'));
        } else {
          this.loggedIn = false;
          this.toastr.danger(this.translate.instant('candidate_login.error_message'),
            this.translate.instant('candidate_login.login_error'));
        }
      },
    );
    if (this.loggedIn) {
      return true;
    }
    return false;
  }

  register(trainingProviderId: string, registerData) {
    const API_URL = `${environment.apiUrl}/api/company/${trainingProviderId}/user/registration`;

    return this.http.post<unknown>(API_URL, registerData).pipe(
      map((data: { status: string }) => data),
      catchError((error) => throwError(error)),
    );
  }

  updateProfile(trainingProviderId: string, registerData) {
    const user = JSON.parse(localStorage.getItem('userData'));
    const headers = new HttpHeaders({
      Authorization: `Bearer ${user.token}`,
      'Content-Type': 'application/json',
    });

    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${user.user.trainingProviderId}/user/${user.user.userId}`;

    return this.http.put<unknown>(API_URL, registerData, options).pipe(
      map((data: {
        status: string,
        data: {
          firstName: string,
          lastName: string,
          hallTicket: string,
          mobileNumber: string,
          profilePicPath: string,
        }
      }) => {
        if (data.status === 'Success') {
          const updatedData = JSON.parse(localStorage.getItem('userData'));
          updatedData.user.firstName = data.data.firstName;
          updatedData.user.lastName = data.data.lastName;
          updatedData.user.mobileNumber = data.data.mobileNumber;
          updatedData.user.hallTicket = data.data.hallTicket;
          updatedData.user.profilePicture = data.data.profilePicPath;
          this.profilePicPath = data.data.profilePicPath;

          localStorage.setItem('userData', JSON.stringify(updatedData));
        }
        return data;
      }),
      catchError((error) => throwError(error)),
    );
  }

  getUserProfile() {

    const user = JSON.parse(localStorage.getItem('userData'));
    const headers = new HttpHeaders({
      Authorization: `Bearer ${user.token}`,
      'Content-Type': 'application/json',
    });

    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${user.user.trainingProviderId}/user/${user.user.userId}/profile`;

    return this.http.get<unknown>(API_URL, options).pipe(
      map((data: {status: string, data: UserProfile}) => data),
      catchError((error) => throwError(error)),
    );
  }

  logOut() {
    localStorage.removeItem('userData');
    localStorage.removeItem('examDetails');
    this.loggedIn = false;
    this.router.navigate(['/auth/login']);
  }
}
