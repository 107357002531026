/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivateDirective } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateDirective> {
  canDeactivate(component: ComponentCanDeactivateDirective): boolean {
    if (!component.canDeactivate()) {
      // if (confirm('Once the exam is completed, it can not be attempted again. Are you sure you want to leave this page?')) {
      //   return true;
      // }
      // return false;
      alert('You are not allowed to leave this page while the exam is in progress.');
      return false;
    }
    return true;
  }
}


// export const canDeactivateGuard: CanDeactivateFn<ComponentCanDeactivateDirective> = (route, state) => {
//   const router = inject(Router);
//   const component = inject(ComponentCanDeactivateDirective);

//   if (!component.canDeactivate()) {
//     // if (confirm('Once the exam is completed, it can not be attempted again. Are you sure you want to leave this page?')) {
//     //   return true;
//     // }
//     // return false;
//     alert('You are not allowed to leave this page while the exam is in progress.');
//     return false;
//   }
//   return true;
// };
