import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {
  NbComponentStatus,
  NbDateService,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';
import { ExamCreationService } from '../../../services/training-provider/exam-creation/exam-creation.service';
import {
  GetServicesModel,
  ServiceModel,
} from '../../../modules/interface/services.interface';
import { QuestionBankService } from '../../../services/training-provider/question-bank/question-bank.service';
import { TableFilter } from '../../../modules/functions/tableFilter';
import { QuestionPaperService } from '../../../services/training-provider/question-papers/question-paper.service';
import { DeleteConfirmDialogComponent } from '../dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { environment } from '../../../../environments/environment';
import { TableFilterService } from '../../../modules/shared/services/filter/table-filter.service';

@Component({
  selector: 'ngx-print-questions',
  templateUrl: './print-questions.component.html',
  styleUrls: ['./print-questions.component.scss'],
})
export class PrintQuestionsComponent implements OnInit {
  rows = [];

  dataLoading = true;

  initially = false;

  temp = [];

  positions = NbGlobalPhysicalPosition;

  ServiceList: ServiceModel[];

  selectedItem: string;

  dataCount = 5;

  currentPage = 0;

  totalRecords = 0;

  totalPages: number;

  maxRows = 5;

  searchTerm = '';

  sortingColumn = '';

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private router: Router,
    private examCreationService: ExamCreationService,
    private questionsService: QuestionBankService,
    private quesPaperService: QuestionPaperService,
    private toastrService: NbToastrService,
    private translate: TranslateService,
    public dialog: NbDialogService,
    protected dateService: NbDateService<Date>,
    private filterService: TableFilterService,
  ) {}

  ngOnInit(): void {
    this.initially = true;
    this.getService();
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/questionTable.json');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }

  createQuestionPaper() {
    this.router.navigate(['pages/training-provider/create-question']);
  }

  // updateFilter(event) {
  //   const val = event.target.value.toLowerCase();
  //   // filter our data
  //   // eslint-disable-next-line arrow-body-style
  //   const temp = this.temp.filter((d) => {
  //     return d.created_by.toLowerCase().indexOf(val) !== -1 || !val;
  //   });

  //   // update the rows
  //   this.rows = temp;
  //   // Whenever the filter changes, always go back to the first page
  //   this.table.offset = 0;
  // }

  async updateFilter(event) {
    this.rows = await new TableFilter().updateFilter(event, this.temp);
  }

  getService() {
    this.examCreationService
      .getAllServices()
      .subscribe((res: GetServicesModel) => {
        if (res.status === 'Success') {
          this.ServiceList = res.data;
          this.selectedItem = this.filterService.checkFilterValue('question-papers', { serviceId : res.data[0].serviceId }, this.selectedItem).serviceId;
          this.getQuestionPapers(this.selectedItem);
        } else {
          this.showToast(
            'danger',
            this.translate.instant('employee.print_question.errors.service'),
            this.translate.instant(
              'employee.print_question.errors.service_title',
            ),
          );
        }
      });
  }

  getQuestionPapers(serviceId: string) {
    const apiPage = this.currentPage + 1;
    this.dataLoading = true;
    this.questionsService
      .getQuesFromPrintDetailsbyService(serviceId, apiPage, this.maxRows, this.searchTerm, this.sortingColumn)
      .subscribe((res) => {
        if (res.status === 'Success') {
          this.totalPages = res.totalPages;
          this.totalRecords = res.totalRecords;
          this.dataLoading = false;
          this.filterService.setFilterValue('question-papers', { serviceId });
          this.temp = res.data.map((ques) => {
            // eslint-disable-next-line no-param-reassign
            ques.createdOn = this.dateService.format(
              new Date(ques?.createdOn),
              'dd-MMM-yyyy',
            );
            // eslint-disable-next-line no-param-reassign
            ques.isPublished =
              ques.isPublished === 1 ? 'Published' : 'Unpublished';

            return ques;
          });
          this.rows = this.temp;
          // if (this.rows.length === 0)
          //   this.showToast(
          //     'danger',
          //     this.translate.instant(
          //       'employee.print_question.errors.not_found',
          //     ),
          //     this.translate.instant(
          //       'employee.print_question.errors.not_found_title',
          //     ),
          //   );
        }
        // if (this.rows.length !== 0) {
        //   this.dataLoading = false;
        //   this.showToast(
        //     'danger',
        //     this.translate.instant(
        //       'employee.print_question.errors.ques_papers',
        //     ),
        //     this.translate.instant(
        //       'employee.print_question.errors.ques_papers_title',
        //     ),
        //   );
        // }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageChange(event: any) {
    this.currentPage = event.offset;
    this.getQuestionPapers(this.selectedItem);
  }

  onDataCountChange(newSize: number) {
    this.maxRows = newSize;
    this.currentPage = 0;
    this.getQuestionPapers(this.selectedItem);
  }

  onSearchChange() {
    this.getQuestionPapers(this.selectedItem);
  }

  onSort(event: { sorts: Array<{ prop: string, dir: string }> }) {
    if (event.sorts.length > 0) {
      const sort = event.sorts[0];
      this.sortingColumn = `${sort.prop},${sort.dir}`;
      this.getQuestionPapers(this.selectedItem);
    }
  }

  downloadFile(url: string, fileName: string): void {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.download = fileName;
    anchor.click();
    document.removeChild(anchor);
  }

  DownloadQuesPaper(row, position, status ) {
    this.quesPaperService.downloadQuesPaper(row.questionPaperId).subscribe(
      (res: {status: string, data: string}) => {
        if (res.status === 'Success') {
          this.downloadFile(`${environment.apiUrl}/${res?.data}`, 'ExportedQuestionPaper');
        } else {
          this.toastrService.show(
            this.translate.instant('employee.print_question.errors.question_paper_download_error'),
            this.translate.instant('employee.print_question.errors.question_paper_download_title'),
            { position, status },
          );
        }
      }, (err) => {
        if (err.status) {
          this.toastrService.show(
            this.translate.instant('employee.print_question.errors.question_paper_download_error'),
            this.translate.instant('employee.print_question.errors.question_paper_download_title'),
            { position, status },
          );
        }
      });
  }

  deleteQuesPaper(position, status, row) {
    this.dataLoading = false;
    this.openDeleteDialog(
      'Are you sure you want to delete the following question paper?',
      row?.questionPaperName || row?.questionPaperId || '',
    ).then((result) => {
      if (result === true) {
        this.quesPaperService.deleteQuesPaper(row.questionPaperId).subscribe(
          (res: { status: string }) => {
            if (res.status === 'Success') {
              this.dataLoading = false;
              // eslint-disable-next-line no-param-reassign
              status = 'success';
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/pages/training-provider/print-questions']);
              });
              this.toastrService.show(
                this.translate.instant(
                  'employee.print_question.success.quesPaperDeletion',
                ),
                this.translate.instant(
                  'employee.print_question.success.quesPaperDeletionTitle',
                ),
                { position, status },
              );
              // this.getBatch(this.trainingProviderId);
            } else {
              this.dataLoading = false;
              this.toastrService.show(
                this.translate.instant(
                  'employee.print_question.errors.quesPaperDeletion',
                ),
                this.translate.instant(
                  'employee.print_question.errors.quesPaperDeletionTitle',
                ),
                { position, status },
              );
            }
          },
          () => {
            this.dataLoading = false;
            this.toastrService.show(
              this.translate.instant(
                'employee.print_question.errors.quesPaperDeletion',
              ),
              this.translate.instant(
                'employee.print_question.errors.quesPaperDeletionTitle',
              ),
              { position, status },
            );
          },
        );
      }
    });
  }

  openDeleteDialog(title: string, message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const dialog = this.dialog.open(DeleteConfirmDialogComponent, {
        context: { title, message },
        closeOnBackdropClick: true,
        hasBackdrop: true,
        closeOnEsc: true,
      });

      dialog.onClose.subscribe((dataFromDialog) => {
        resolve(dataFromDialog === true);
      });
    });
  }

  goToEditPaper(quesPaperId) {
    this.router.navigate([
      '/pages/training-provider/edit-question-paper',
      quesPaperId,
    ]);
  }

  showToast(status: NbComponentStatus, message: string, statusMessage: string) {
    this.toastrService.show(`${message}`, statusMessage, { status });
  }
}
