import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from '../../service/auth.service';
import { LoginParams } from '../../../modules/interface/auth.interface';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: NbToastrService,
  ) {}

  submitted = false;

  showPassword = false;

  user = {
    email: '',
    password: '',
    rememberMe: false,
  };

  ngOnInit(): void {
    // Check if the user is already logged in
    if (this.authService.loggedIn) {
      // Redirect to the "profile" route
      this.router.navigate(['/profile']);
    }
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  async login(value: LoginParams): Promise<void> {
    this.submitted = true;
    const res = await this.authService.login(this.user.email, this.user.password);
    this.submitted = res;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  getInputType(): string {
    return this.showPassword ? 'text' : 'password';
  }
}
