<nb-card>
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <div>{{'change_password.title' | translate}}</div>
    <nb-icon icon="close-outline" (click)="dialogRef.close()"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <label for="oldPassword" class="label">
        {{'change_password.old_password' | translate}}
         <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        nbInput
        fullWidth
        formControlName="oldPassword"
        placeholder="Old Password"
        [status]="getFormControlStatus('oldPassword')"
        class="mt-2 mb-1"
      />
      <div *ngIf="isInvalid('oldPassword')" class="mt-0">
        <div *ngIf="hasError('oldPassword', 'required')" class="error-message">
          {{'change_password.errors.old_password_required' | translate}}
        </div>
        <div *ngIf="hasError('oldPassword', 'maxlength')" class="error-message">
          {{'change_password.errors.max_length' | translate}}
        </div>
      </div>

      <label for="newPassword" class="label">
        {{'change_password.new_password' | translate}}
        <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        nbInput
        fullWidth
        formControlName="newPassword"
        placeholder="New Password"
        [status]="getFormControlStatus('newPassword')"
        class="mt-2 mb-1"
      />
      <div *ngIf="isInvalid('newPassword')" class="mt-0">
        <div *ngIf="hasError('newPassword', 'required')" class="error-message">
          {{'change_password.errors.new_password_required' | translate}}
        </div>
        <div *ngIf="hasError('newPassword', 'minlength')" class="error-message">
          {{'change_password.errors.min_length' | translate}}
        </div>
        <div *ngIf="hasError('newPassword', 'maxlength')" class="error-message">
          {{'change_password.errors.max_length' | translate}}
        </div>
        <div *ngIf="hasError('newPassword', 'noCapitalCase')" class="error-message">
          {{ 'change_password.errors.password_contains' | translate }} {{ 'change_password.errors.capital' | translate }}
        </div>
        <div *ngIf="hasError('newPassword', 'noSmallCase')" class="error-message">
          {{ 'change_password.errors.password_contains' | translate }} {{ 'change_password.errors.small' | translate }}
        </div>
        <div *ngIf="hasError('newPassword', 'noNumber')" class="error-message">
          {{ 'change_password.errors.password_contains' | translate }} {{ 'change_password.errors.number' | translate }}
        </div>
        <div *ngIf="hasError('newPassword', 'noSpecialCharacter')" class="error-message">
          {{ 'change_password.errors.password_contains' | translate }} {{ 'change_password.errors.special' | translate }}
        </div>
      </div>

      <label for="confirmPassword" class="label">
        {{'change_password.confirm_password' | translate}}
        <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        nbInput
        fullWidth
        formControlName="confirmPassword"
        placeholder="Confirm Password"
        [status]="getFormControlStatus('confirmPassword')"
        class="mt-2 mb-1"
      />
      <div *ngIf="isInvalid('confirmPassword')" class="mt-0">
        <div *ngIf="hasError('confirmPassword', 'required')" class="error-message">
          {{'change_password.errors.confirm_password_required' | translate}}
        </div>
        <div *ngIf="hasError('confirmPassword', 'passwordMismatch')" class="error-message">
          {{'change_password.errors.mismatch' | translate}}
        </div>
      </div>

    </form>
    <div class="mt-2">
      <!-- Submit Button -->
      <button
        nbButton
        hero
        class="float-right ml-2"
        status="primary"
        (click)="onSubmit()"
        [disabled]="myForm.invalid"
      >
        {{'global.submit' | translate}}
      </button>

      <!-- Reset Button -->
      <button
        nbButton
        hero
        class="float-right ml-2"
        status="danger"
        (click)="resetForm()"
      >
      {{'global.reset' | translate}}
      </button>
    </div>
  </nb-card-body>
</nb-card>