import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  activeMenu: NbMenuItem[] = [];

  superAdminMenu: NbMenuItem[] = [
    {
      title: 'Companies',
      link: 'super-admin/companies',
      icon: 'list-outline',
    },
    // TODO: REMOVE COMMENT WHEN ADDING PROFILE
    // {
    //   title: 'Profile',
    //   link: 'super-admin/profile',
    //   icon: 'shield-outline',
    // },
  ];

  candidateMenu: NbMenuItem[] = [
    {
      title: 'Exams',
      icon: 'browser-outline',
      link: 'candidate/exams-list',
    },
  ];

  companyMenu: NbMenuItem[] = [
    {
      title: 'Services',
      link: 'training-provider/services',
      icon: 'briefcase-outline',
    },
    {
      title: 'Batches',
      link: 'training-provider/batches',
      icon: 'cube-outline',
    },
    {
      title: 'Sections',
      link: 'training-provider/sections',
      icon: 'layout-outline',
    },
    {
      title: 'Subsections',
      link: 'training-provider/sub-sections',
      icon: 'layers-outline',
    },
    // TODO: UNCOMMENT WHEN GROUP IS IMPLELEMTED
    // {
    //   title: 'Group Questions',
    //   link: 'training-provider/group-questions',
    //   icon: 'people-outline',
    // },
    {
      title: 'Question bank',
      link: 'training-provider/question-bank',
      icon: 'book-outline',
    },
    // INFO: This is not being implemented right now
    // {
    //   title: 'Article/News',
    //   link: 'training-provider/news',
    //   icon: 'file-text-outline',
    // },
    {
      title: 'Exam creation',
      link: 'training-provider/exam-creation',
      icon: 'book-open-outline',
    },
    {
      title: 'Question papers',
      link: 'training-provider/print-questions',
      icon: 'clipboard-outline',
    },
    {
      title: 'Candidate images',
      link: 'training-provider/candidate-images',
      icon: 'people-outline',
    },
  ];

  setSideMenuOptions(userRole: string) {
    switch (userRole) {
      case 'TrainingProvider':
        this.activeMenu = this.companyMenu;
        break;
      case 'SuperUser':
        this.activeMenu = this.superAdminMenu;
        break;
      case 'Trainee':
        this.activeMenu = this.candidateMenu;
        break;
      default:
        break;
    }
  }
}
