import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttendanceDetailsComponent } from './attendance-details/attendance-details.component';
import { BatchAllocationComponent } from './batch-allocation/batch-allocation.component';
import { MarksComponent } from './marks/marks.component';
import { PaymentComponent } from './payment/payment.component';
import { PrintQuestionsComponent } from '../training-provider/print-questions/print-questions.component';
import { RegistrationComponent } from './registration/registration.component';
import { VerifyCandidateComponent } from './verify-candidate/verify-candidate.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceComponent } from './invoice/invoice.component';

const routes: Routes = [
  { path: '', redirectTo: 'registration', pathMatch: 'full' },
  { path: 'registration', component: RegistrationComponent },
  { path: 'verify-candidate', component: VerifyCandidateComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'batch-allocation', component: BatchAllocationComponent },
  { path: 'invoice', component: InvoiceComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'attendance-details', component: AttendanceDetailsComponent },
  { path: 'marks', component: MarksComponent },
  { path: 'print-questions', component: PrintQuestionsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeesRoutingModule { }
