/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/service/auth.service';
// import { BatchesAPI } from '../../../modules/interface/batches.interface';
import { QuestionPaperAPI } from '../../../modules/interface/create-exam.interface';
import { GetServicesModel } from '../../../modules/interface/services.interface';
import { GetBatchesInterface } from '../../../modules/interface/batches.interface';
import {
  ExamCreationAPI,
  GetExportedAPI,
  GetExamDetailsById,
  GetStudentsMarks,
  UpdateCreationAPI,
} from '../../../modules/interface/getExams.interface';

@Injectable({
  providedIn: 'root',
})
export class ExamCreationService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  static getQueryParams(name, values) {
    let str = '&';
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  static getQuerySingleParam(name, value) {
    let str = '&';
    str += `${name}=${value}`;
    return str;
  }

  getAllServices(): Observable<GetServicesModel> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/services`;
    return this.httpClient.get<GetServicesModel>(API_URL).pipe(
      map((data: GetServicesModel) => data),
      catchError((error) => throwError(error)),
    );
  }

  getAllUsers<T>(): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/users`;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${userObject.token}`, // Add authorization header if required
      'Content-Type': 'application/json', // Add other headers as needed
    });

    // Set up options with headers
    const options = { headers };
    return this.httpClient.get<T>(API_URL, options).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  getAllSection<T>(): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/sections`;
    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  getAllBatches(): Observable<GetBatchesInterface> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/batches`;
    return this.httpClient.get<GetBatchesInterface>(API_URL).pipe(
      map((data: GetBatchesInterface) => data),
      catchError((error) => throwError(error)),
    );
  }

  getAllQuesPapers(): Observable<QuestionPaperAPI> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpapers/list`;
    return this.httpClient.get<QuestionPaperAPI>(API_URL).pipe(
      map((data: QuestionPaperAPI) => data),
      catchError((error) => throwError(error)),
    );
  }

  getAllExams<T>(
    userId: string,
    currentPage: number,
    maxRows: number,
    searchTerm = '',
    sortingColumn = '',
  ): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userId}/exams`;
    const params = new HttpParams()
      .set('currentPage', currentPage.toString())
      .set('maxRows', maxRows.toString())
      .set('searchTerm', searchTerm)
      .set('sortingColumn', sortingColumn);
    const headers = new HttpHeaders({
      Authorization: `Bearer ${userObject.token}`,
      'Content-Type': 'application/json',
    });
    const options = { headers, params };

    return this.httpClient.get<T>(API_URL, options).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  createExam(examDetails): Observable<ExamCreationAPI> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam`;
    return this.httpClient.post<ExamCreationAPI>(API_URL, examDetails).pipe(
      map((data: ExamCreationAPI) => data),
      catchError((error: any) => {
        console.error('Error occurred while creating exam:', error); // Log the error response
        return throwError(error);
      }),
    );
  }

  getStudentMarks(examId): Observable<GetStudentsMarks> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam/${examId}/candidates/marks`;
    return this.httpClient.get<GetStudentsMarks>(API_URL).pipe(
      map((data: GetStudentsMarks) => data),
      catchError((error) => throwError(error)),
    );
  }

  updateExams(examId, formData): Observable<UpdateCreationAPI> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam-update/${examId}`;
    return this.httpClient.put<UpdateCreationAPI>(API_URL, formData).pipe(
      map((data: UpdateCreationAPI) => data),
      catchError((error) => throwError(error)),
    );
  }

  getExamDetailsById(examId): Observable<GetExamDetailsById> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam/${examId}`;
    return this.httpClient.get<GetExamDetailsById>(API_URL).pipe(
      map((data: GetExamDetailsById) => data),
      catchError((error) => throwError(error)),
    );
  }

  getMarksById(examId): Observable<GetExportedAPI> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam/${examId}/candidates/marks/export`;
    return this.httpClient.get<GetExportedAPI>(API_URL).pipe(
      map((data: GetExportedAPI) => data),
      catchError((error) => throwError(error)),
    );
  }

  deleteExam(examId: number) {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam/${examId}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${userObject.token}`,
      'Content-Type': 'application/json',
    });
    const options = { headers };
    return this.httpClient
      .delete(API_URL, options)
      .pipe(catchError((error) => throwError(error)));
  }

  downloadAnswers(userId: string, examId: string) {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/user/${userId}/exam/${examId}/submittedanswers/export`;
    return this.httpClient.get<GetExportedAPI>(API_URL).pipe(
      map((data: GetExportedAPI) => data),
      catchError((error) => throwError(error)),
    );
  }

  markExamAsCompleted(examId: number) {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam/${examId}/candidates/examphotos/export`;
    return this.httpClient
      .put(API_URL, '')
      .pipe(catchError((error) => throwError(error)));
  }
}
