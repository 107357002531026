/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { token } = JSON.parse(localStorage.getItem('userData')) || { token: null };

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', `bearer ${token}`) });
    }

    // Checks if 'Content-Type' is not defined
    if (!request.headers.has('Content-Type')) {
      // Checks if URL does not end with 'import'
      if (request.url.split('/').slice(-1)[0] !== 'import') {
        // Checks if the request body is FormData
        if (request.body instanceof FormData) {
          // Does nothing for FormData
        } else {
          // Sets the headers for JSON
          request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
      }
    }

    return next.handle(request).pipe(
      // tap((event: HttpEvent<unknown>) => {
      //   console.log('Incoming HTTP response', event);
      // }),

      tap(() => {
        // console.log('Incoming HTTP response', event);
      }),
    );
  }
}
