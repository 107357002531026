import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionPaperService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) { }

  static getQuerySingleParam(name, value) {
    let str = '&';
    str += `${name}=${value}`;
    return str;
  }

  getQuestionPaperDetails<T>(printDetailId: string | number): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpaper/${printDetailId}`;
    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  getExamsByQuesPaperId<T>(printDetailId: string): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/question/${printDetailId}/exams`;
    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  getExamsByServiceId<T>(serviceId: string, allExams?: boolean): Observable<T> {
    const userObject = this.authService.getUser();
    let API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/service/${serviceId}/exams`;
    if (allExams === true) API_URL += '?all=true';
    const headers = new HttpHeaders({
      Authorization: `Bearer ${userObject.token}`, // Add authorization header if required
      'Content-Type': 'application/json', // Add other headers as needed
    });

    // Set up options with headers
    const options = { headers };
    return this.httpClient.get<T>(API_URL, options).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  deleteQuesPaper<T>(questionPaperId: string): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpaper/${questionPaperId}`;
    return this.httpClient.delete<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  downloadQuesPaper<T>(questionPaperId: string): Observable<T> {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questions/${questionPaperId}/export`;
    return this.httpClient.get<T>(API_URL).pipe(
      map((data: T) => data),
      catchError((error) => throwError(error)),
    );
  }

  publishQuesPaper(id: string | number) {
    const userObject = this.authService.getUser();
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/questionpaper/${id}/publish`;
    return this.httpClient.put(API_URL, '').pipe(
      map((data: { status: string }) => data),
      catchError((error) => throwError(error)),
    );
  }

  createQuesPaperSet(examId, questionPaperId) {
    const userObject = this.authService.getUser();
    // Publishes an existing Question Paper

    const headers = new HttpHeaders({
      Authorization: `bearer ${userObject.token}`, // Add authorization header if required
      'Content-Type': 'application/json', // Add other headers as needed
    });

    // Set up options with headers
    const options = { headers };
    const API_URL = `${environment.apiUrl}/api/company/${userObject.user.trainingProviderId}/exam/${examId}/questionPaper/${questionPaperId}`;
    return this.httpClient.post(API_URL, '', options).pipe(
      map((data: { status: string }) => data),
      catchError((error) => throwError(error)),
    );
  }

}
