import { EventEmitter, Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NgxCaptureService } from 'ngx-capture';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  public fullscreenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public tabSwitchWarning = false;

  public submitAnswerAndExit = false;

  capturedScreenshot;

  private examCompleted = true;

  toastrCount = 0;

  toastrVisible: boolean;

  eventEmitted = false;

  public fullScreenMode = false;

  constructor(public toastrService: NbToastrService,
    public translate: TranslateService,
    private captureService: NgxCaptureService) {}

  initListeners() {
    if (this.fullScreenMode === true) {
      this.disableRightClick();
      this.checkFullscreenStatus();
      this.detectTabSwitch();
      this.detectThreeFingerGesture();
      document.addEventListener('click', () => {
        if (!document.fullscreenElement && !this.examCompleted) {
          this.enterFullscreen();
        }
      });
    }
  }

  getExamCompleted(): boolean {return this.examCompleted;}

  setExamCompleted(value: boolean): void {
    this.examCompleted = value;
  }

  private disableRightClick() {
    window.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }

  private async checkFullscreenStatus() {
    const elem = document.documentElement;

    document.addEventListener('fullscreenchange', () => {
      if (!elem && !this.examCompleted) {
        this.emitEvent();
        // this.toastrService.danger(
        //   this.translate.instant('employee.exam.errors.full_screen_warning'),
        //   this.translate.instant('employee.exam.errors.full_screen_warning_title'));
        this.showTabSwitchWarning();
        this.enterFullscreen();
      }
      this.submitAnswerAndExit = false;
    });

    this.enterFullscreen();
  }

  private enterFullscreen() {
    const elem = document.documentElement;

    if (!document.fullscreenElement && !this.examCompleted) {
      this.emitEvent();
      elem.requestFullscreen().catch(() => {
        // this.showTabSwitchWarning();
      });
    }
  }

  private detectTabSwitch() {
    window.addEventListener('keydown', (e) => {
      if (e.altKey && e.key === 'Tab') {
        if (document.fullscreenElement) {
          e.preventDefault();
        }
        this.showTabSwitchWarning('Alt + Tab Key');
      }
    });

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        // Full screen was exited
        this.showTabSwitchWarning('Escape Key');
      }
    });

    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key >= '1' && e.key <= '9') {
        e.preventDefault();
        this.showTabSwitchWarning(`Ctrl + ${e.key}`);
      } else if (e.ctrlKey && e.key === 't' ) {
        e.preventDefault();
        this.showTabSwitchWarning('Ctrl Key');
      } else if (e.ctrlKey && e.key === 'T' ) {
        e.preventDefault();
        this.showTabSwitchWarning('Ctrl Key');
      } else if (e.ctrlKey && e.key === 'r' ) {
        e.preventDefault();
        this.showTabSwitchWarning('Ctrl Key');
      } else if (e.ctrlKey && e.key === 'R' ) {
        e.preventDefault();
        this.showTabSwitchWarning('Ctrl Key');
      } else if (e.ctrlKey) {
        e.preventDefault();
        this.showTabSwitchWarning('Ctrl Key');
      } else if (e.key === 'Escape') {
        e.preventDefault();
        this.showTabSwitchWarning('Escape Key');
      } else if (e.key === 'Meta') {
        e.preventDefault();
        this.showTabSwitchWarning('Meta Key');
      } else if (e.key === 'F1') {
        e.preventDefault();
        this.showTabSwitchWarning('F1 Key');
      } else if (e.key === 'F2') {
        e.preventDefault();
        this.showTabSwitchWarning('F2 Key');
      } else if (e.key === 'F3') {
        e.preventDefault();
        this.showTabSwitchWarning('F3 Key');
      } else if (e.key === 'F4') {
        e.preventDefault();
        this.showTabSwitchWarning('F4 Key');
      } else if (e.key === 'F5') {
        e.preventDefault();
        this.showTabSwitchWarning('F5 Key');
      } else if (e.key === 'F6') {
        e.preventDefault();
        this.showTabSwitchWarning('F6 Key');
      } else if (e.key === 'F7') {
        e.preventDefault();
        this.showTabSwitchWarning('F7 Key');
      } else if (e.key === 'F8') {
        e.preventDefault();
        this.showTabSwitchWarning('F8 Key');
      } else if (e.key === 'F9') {
        e.preventDefault();
        this.showTabSwitchWarning('F9 Key');
      } else if (e.key === 'F10') {
        e.preventDefault();
        this.showTabSwitchWarning('F10 Key');
      } else if (e.key === 'F11') {
        e.preventDefault();
        this.showTabSwitchWarning('Meta Key');
      } else if (e.key === 'F12') {
        e.preventDefault();
        this.showTabSwitchWarning('F12 Key');
      } else if (e.key === 'Tab') {
        e.preventDefault();
        this.showTabSwitchWarning('Tab Key');
      } else if (e.altKey) {
        e.preventDefault();
        this.showTabSwitchWarning('Alt Key');
      } else if (e.shiftKey && e.key === 'a') {
        e.preventDefault();
        this.showTabSwitchWarning('Ctrl + Shift + A');
      }
    }, true);
  }

  private detectThreeFingerGesture() {
    document.addEventListener('gesturestart', (e) => {
      e.preventDefault();
      this.showTabSwitchWarning('Three-finger Trackpad Gesture');
    });

    let touchesCount = 0;
    document.addEventListener('touchstart', (e) => {
      touchesCount = e.touches.length;
    });

    document.addEventListener('touchmove', (e) => {
      touchesCount = e.touches.length;
    });

    document.addEventListener('touchend', () => {
      if (touchesCount === 3) {
        this.showTabSwitchWarning('Three-finger Trackpad Gesture');
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  async showTabSwitchWarning(method?) {
    if (this.toastrVisible) {
      return;
    }

    if (!document.fullscreenElement && !this.examCompleted) {
      this.emitEvent();
    }
    this.toastrService.danger(
      this.translate.instant('employee.exam.errors.tab_switch_warning'),
      this.translate.instant('employee.exam.errors.tab_switch_warning_title'),
    );

    this.toastrVisible = true;

    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, 3000));

    this.toastrVisible = false;

  }

  public togglesubmitAnswerAndExit() {
    this.submitAnswerAndExit = !this.submitAnswerAndExit;
  }

  captureScreenShot(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.captureService.getImage(document.body, true).subscribe(
        (img: string) => {
          this.capturedScreenshot = img;
          if (!document.fullscreenElement) {
            this.emitEvent();
          }
          resolve(img); // Resolve the promise with the captured image
        },
        (error) => {
          console.error('Error capturing screenshot:', error);
          reject(error); // Reject the promise if there's an error
        },
      );
    });
  }

  async emitEvent() {
    if (this.eventEmitted) {
      return;
    }

    this.fullscreenChanged.emit(false);

    this.eventEmitted = true;

    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, 3000));

    this.eventEmitted = false;

  }

  removeEventListeners() {
    if (this.examCompleted === true || this.fullScreenMode === false) {
      window.removeAllListeners('keydown');
      window.removeAllListeners('contextmenu');
      document.removeAllListeners('fullscreenchange');
      document.removeAllListeners('gesturestart');
      document.removeAllListeners('touchstart');
      document.removeAllListeners('touchmove');
      document.removeAllListeners('touchend');
      document.removeAllListeners('click');
    }
  }
}
